@import "~bootswatch/dist/cyborg/variables";
@import "~bootstrap-scss/bootstrap";
@import "~bootswatch/dist/cyborg/bootswatch";

@import '~react-datetime/css/react-datetime.css';

@import "components/OutsideContainer";

body {
  background: #000000 url("../gfx/bg30.png") no-repeat 50px 50px;
  background-size: 300px auto;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}